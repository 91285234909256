import * as React from "react"
import { Head } from "../../components/head"
import { Layout } from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { ExpansionPanel } from "../../components/_packages/expansionPanel"
import { ExpandedPanel } from "../../components/_packages/expandedPanel"
import { branding } from "../../content/packageContent"
import BackgroundImage from "gatsby-background-image"
import {
  RelatedPackages,
  Package,
} from "../../components/_packages/relatedPackages"
import { SectionContainer } from "../../components/shared/containers"
import { Header } from "../../components/shared/type"

const BrandDevelopmentPage = (): JSX.Element => {
  const { brandingImg } = useStaticQuery(
    graphql`
      query {
        brandingImg: file(relativePath: { eq: "services/branding.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const brandingFluid = brandingImg.childImageSharp.fluid

  return (
    <>
      <SEO title="Brand Development" />
      <Head title={"Brand Development"} />
      <Layout>
        <SectionContainer style={{paddingBottom: "2rem"}}>
          <PackageHeader>
            <FluidImg
              fluid={brandingFluid}
              style={{ backgroundSize: "contain" }}
            />
            <div>
              <Header>Brand Development</Header>
              <Description>{branding.description}</Description>
            </div>
          </PackageHeader>
        </SectionContainer>

          <PanelsSmall>
            <ExpansionPanel
              plan={branding.plans.logo}
              package="Brand Development"
              expanded={true}
            />
            <ExpansionPanel
              plan={branding.plans.dev}
              package="Brand Development"
            />
          </PanelsSmall>

          <Flex>
            <ExpandedPanel
              plan={branding.plans.logo}
              package="Brand Development"
            />
            <ExpandedPanel
              plan={branding.plans.dev}
              package="Brand Development"
            />
          </Flex>

          <RelatedPackages currentPackage={Package.BRAND} />
      </Layout>
    </>
  )
}

const Container = styled.div`
  grid-column: 1/-1;
  margin-top: 3rem;
  @media (min-width: 768px) {
    display: grid;
    #mobile-hr {
      display: none;
    }

    margin-bottom: 8rem;
  }
`

const Description = styled.p`
  @media (min-width: 768px) {
  }
`

const Features = styled.div`
  background-color: var(--white);

  @media (min-width: 768px) {
    background-color: var(--grey);
    padding: 3rem 0;
    margin-bottom: 6rem;
  }
`

const Flex = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  height:100%;
  padding-left: 50px;
  padding-right: 50px;
`

const FluidImg = styled(BackgroundImage)`
  height: 200px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    height: 300px;
    width: 400px;
    justify-self: end;
  }
`

const PackageHeader = styled.div`
 @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-column-gap: 75px;
  }
`

const PanelsSmall = styled.div`
  :nth-of-type(3) {
    background-color: var(--grey);
  }

  :nth-of-type(3) > div {
    background-color: var(--grey);
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export default BrandDevelopmentPage
